body, html {
  scroll-behavior: smooth;
}

/* Color variables */
:root {
  --primary-color: #6f70b3;
  --secondary-color: #5451a2;
  --ternary-color: #262060;
}

/* Fixed header */
.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  height: 60px; /* Adjust the height as needed */
  transition: all 0.5s;
  background: var(--ternary-color);
}

.fixed-top img {
  transition: height 0.5s;
}

/* Header at the top of the page */
.top {
  background-color: var(--ternary-color);
  height: 80px;
}

/* Header when scrolling */
.scrolled {
  background: linear-gradient(180deg, rgba(38, 32, 96, 0.9) 0%, rgba(38, 32, 96, 0.6) 100%);
  height: 60px;
  backdrop-filter: blur(10px);
}

.navbar-brand,
.nav-link {
  color: #fff !important;
}

.nav-link:hover {
  color: #ccc !important;
}

/* Home section */
#home {
  background-color: var(--primary-color);
}

#home .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 80px);
}

#home {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
  background-color: var(--primary-color);
}

#home .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* About section */
#about {
  background-color: var(--secondary-color);
  color: #fff;
}

/* Footer */
footer {
  background-color: var(--ternary-color);
  color: #fff;
}

/* Responsive styles for small screens */
@media (max-width: 992px) {
  .navbar-toggler {
    border-color: #fff;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }

  .navbar-collapse {
    background-color: var(--ternary-color);
  }

  .nav-link {
    padding: 8px 16px;
  }
}

.logo-scrolled {
  height: 20px !important; /* Adjust height as needed */
  transition: all 0.5s;
}

@media (min-width: 768px) {
  .input-group > .form-control {
    width: calc(100% - 140px); /* 50% screen width minus button width */
  }
  .input-group > .btn {
    width: 140px; /* Button width on large screens */
  }
}
